<template>
    <div class="outerBox" ref="viewBox">
		<div class="innterBox" ref="slot1">
			<slot></slot>
		</div>
		<div class="innterBox" ref="slot2" v-show="doScroll">
			<slot></slot>
		</div>
	</div>
</template>

<style lang="less" scoped>

.outerBox {
	position: relative;
	overflow: hidden;
	
	.innterBox {
		position: absolute;
		width: 100%;
	}
	
}
</style>

<script>
export default {
	props: ['speed','sKey'],
	data(){
		return {
			intervalId: null,
			doScroll: false,
			scrollingSpeed: '22', //可选参数，表示达到滚动条件后滚动屏每秒滚动的像素数，默认22
		}
	},
	methods: {
		setScrolling(){
			clearInterval(this.intervalId);
			let viewHeight = this.$refs.viewBox.offsetHeight;
			let listHeight = this.$refs.slot1.offsetHeight;
			let speed = parseFloat(this.scrollingSpeed);
			let roll = true;
			if(!(speed == 0) && !speed){
				speed = 22;
			}else if(speed == 0){
				roll = false;
			}
			if(listHeight > viewHeight && roll){
				//滚动
				this.doScroll = true;
				let el1 = this.$refs.slot1;
				let el2 = this.$refs.slot2;
				el1.style.top = 0;
				el2.style.top = listHeight;
				let y1 = 0;
				let y2 = listHeight;
				this.intervalId = setInterval(()=>{
					y1--;
					y2--;
					if(y1 <= -listHeight){
						y1 = listHeight;
					}
					if(y2 <= -listHeight){
						y2 = listHeight;
					}
					el1.style.top = y1 + 'px';
					el2.style.top = y2 + 'px';
					
				}, parseFloat(1000/speed))
				
			}else{
				this.doScroll = false;
				let el1 = this.$refs.slot1;
				let el2 = this.$refs.slot2;
				el1.style.top = 0;
				el2.style.top = listHeight;
				//组件内容更新后如不需要滚动，需清理内存垃圾
				
				clearInterval(this.intervalId);
				
				this.intervalId = null;
			}
		}
	},
	async mounted(){
		this.scrollingSpeed = this.speed;
		let updateSTO = setTimeout(() => {
			this.scrollingSpeed = this.speed;
			this.setScrolling();
			clearTimeout(updateSTO);
		}, 500);
	},
	watch:{
		sKey(){
			let updateSTO = setTimeout(() => {
				this.scrollingSpeed = this.speed;
				this.setScrolling();
				clearTimeout(updateSTO);
			}, 200);
			
		}
	}
}
</script>

